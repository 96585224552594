@import "../assets/styles/var";

@keyframes twelve-meridians-animate
{
  from {top: 0;}
  to {top: 3vh;}
}

.default-main {
  color: $font_subtitle_color;
  overflow-x: hidden;
  background-color: $font_white_bg;
  max-width: 500px;
  margin: 0 auto;

  .detail {
    padding: 10% 0;
    width: 100%;
    background: url("../assets/images/header-bg.png") no-repeat;
    background-size: 100%;
    height: 500px;

    & div {
      height: 100%;
      margin: 0 16%;
      background: url("../assets/images/screen-shot.png") no-repeat;
      background-size: 100% 100%;
    }

    img {
      width: 20%;
      position: relative;
      display: block;

      &.twelve-meridians {
        width: 35%;
        left: 70%;
        animation: twelve-meridians-animate 1.2s linear  infinite alternate;
      }

      &.eight-plus {
        right: 11%;
        width: 25%;
        animation: twelve-meridians-animate 1.4s linear  infinite alternate;
      }

      &.meridian-detail {
        top: 10%;
        width: 30%;
        left: 85%;
        animation: twelve-meridians-animate 1.6s linear  infinite alternate;
      }

      &.strange-point {
        top: 5%;
        width: 25%;
        animation: twelve-meridians-animate 1.8s linear  infinite alternate;
      }
    }
  }

  .logo-title {
    text-align: center;
    margin-top: 10px;

    img {
      height: 40px;
    }
  }

  .tips {
    display: flex;
    margin: 20px 10% 0 10%;
    font-size: 14px;
    justify-content: space-between;
  }

  .action-wrap {
    margin: 10px 10px 25px 10px;
    display: flex;
    justify-content: center;

    img {
      //height: 30px;
    }

    & > div {
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      color: white;
      font-size: 22px;
      padding: 10px;
      width: 60%;
      min-width: 250px;
      text-align: center;
      border-radius: 50px;
      background-color: #6d4628;
    }
  }

  .footer {
    text-align: center;
    margin-bottom: 20px;
    font-size: 12px;
  }
}

.mask {
  position: fixed;
  top: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba($font_black_bg, 0.7);

  & > div {
    display: flex;
    justify-content: space-between;
    background-color: $font_white_bg;
    align-items: center;
    padding: 10px 20px;

    h4 {
      width: 100%;
      text-align: center;
    }

    img {
      width: 40px;
    }
  }
}