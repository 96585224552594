/* 白色*/
$font_white_bg: #ffffff;
/*黑色*/
$font_black_bg: #000000;
// 子标题颜色
$font_subtitle_color: #999999;
/*灰色前景色*/
$font_grey_light: #e8e8e8;
/*灰色前景色*/
$font_grey: #dddddd;
/*灰色*/
$font_grey_bg: #eeeeee;
/*蓝色（浅）*/
$font_grey_light: #F1F5FD;
/*后台头部背景色*/
$font_dark_bg: #001529;
/*蓝色（深）*/
$font_blue_deep: #105DBF;
/*蓝色*/
$font_blue_bg: #1890ff;
/*蓝色（浅）*/
$font_blue_light: #40a9ff;
/*绿色*/
$font_green_bg: #08979c;
/*亮绿色*/
$font_green_light: #52c41a;
/*红色*/
$font_red: #ff4d4f;
/*橙色*/
$font_orange: #fa8c16;
/*紫色*/
$font_purple: #9691f8;