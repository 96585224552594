@import "./assets/styles/var";

body {
  font-family: sans-serif;
  margin: 0;
}

html, body, #root {
  min-width: 100%;
  min-height: 100%;
  margin: 0;
  padding: 0;
}

h1, h2, h3, h4, h5, h6, p, ul, li {
  padding: 0;
  margin: 0;
}

.ant-btn-link {
  padding: 0;
}